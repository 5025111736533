import { rtdb } from "@/helpers/firebase";
// import "firebase/firestore";

const COLLECTION = "/frames/";
const collectionRef = rtdb.ref(COLLECTION);

// const fsCollectionRef = db.collection("Artwork");

class FramesDS {
  // Firestore
  // read(_id = null) {
  //   if (_id === null) {
  //     return fsCollectionRef.get();
  //   } else {
  //     return fsCollectionRef.doc(_id).get();
  //   }
  // }

  

  // RT DB

  getQuadrantInfo(id) {
    return collectionRef.child(`/${id}/`).child(`/quadrants/`).once("value");
  }

  getCoordinateInfo(id) {
    return collectionRef
      .child(`/${id}/`)
      .child(`/coordinateInfo/`)
      .once("value");
  }

  getAvailableFrames(id) {
    return collectionRef
      .child(`/${id}/`)
      .child(`/available/`)
      .orderByKey()
      .once("value");
  }
  getAvailableFramesByQuadrant(id, qId, nRecords) {
    return collectionRef
      .child(`/${id}/`)
      .child(`/available/`)
      .orderByChild("q")
      .equalTo(String(qId))
      .limitToFirst(nRecords)
      .once("value");
  }
  getAvailableFramesLimit(id, nRecords) {
    return collectionRef
      .child(`/${id}/`)
      .child(`/available/`)
      .orderByKey()
      .limitToFirst(nRecords)
      .once("value");
  }
  getUnavailableFrames(id) {
    return collectionRef.child(`/${id}/`).child(`/unavailable/`).once("value");
  }
  getUserArtworkFrames(uid, id) {
    return collectionRef
      .child(`/${id}/`)
      .child("/unavailable/")
      .orderByChild("_collectorId")
      .equalTo(uid)
      .once("value");
  }
  async blockFrames(userid, session, quantity) {
    await collectionRef
      .child(`/blocked/${userid}/${session}/quantity/`)
      .update(quantity);
  }

  async unblockFrames(userid, session) {
    await collectionRef
      .child(`/blocked/${userid}/${session}/quantity/`)
      .remove();
  }

  getUserBlockedFrames(uid, session, workId) {
    return collectionRef.child(`/blocked/${uid}/${session}/cart/${workId}`);
  }

  getCartMetadata(uid, session) {
    return collectionRef.child(`/blocked/${uid}/${session}/metadata/`);
  }
}

export default new FramesDS();
