var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"parent",staticClass:"canvas-grid"},[_c('div',[_c('div',{staticClass:"canvas-grid__coordinates"},[(_vm.enableFrames)?_c('v-checkbox',{attrs:{"label":"highlight unavailable frames"},on:{"change":function($event){return _vm.drawRectangle(false)}},model:{value:(_vm.showUnavailable),callback:function ($$v) {_vm.showUnavailable=$$v},expression:"showUnavailable"}}):_vm._e(),_c('p',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t("selectQuadrant")))]),_c('div',{staticClass:"mt-1 mb-1"},[_vm._v(" "+_vm._s(((_vm.$t("coordinates")) + " " + (_vm.xFrame + 1) + "," + (_vm.yFrame + 1)))+" ")]),_c('canvas',{ref:"canvasZ",staticClass:"canvasZ",attrs:{"id":"canvasZ"}}),_c('div',{staticClass:"text-center mt-4"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('v-icon',[_vm._v("mdi-chevron-left")]),_vm._v(" "+_vm._s(_vm.$t("back"))+" ")],1)],1)],1),_c('div',{staticClass:"canvas-grid__canvaswrapper"},[_vm._v(" "+_vm._s(_vm.debug)+" "),_c('canvas',{ref:"canvasImg",staticClass:"canvasImg",attrs:{"id":"canvasImg","width":_vm.width,"height":_vm.height}}),_c('canvas',{directives:[{name:"show",rawName:"v-show",value:(_vm.showUnavailable),expression:"showUnavailable"}],ref:"canvasFrames",staticClass:"canvasFrames",attrs:{"id":"canvasFrames","width":_vm.width,"height":_vm.height}}),_c('canvas',{ref:"canvasGrid",staticClass:"canvasGrid",attrs:{"id":"canvasGrid","width":_vm.width,"height":_vm.height}}),_c('canvas',{ref:"canvasGridZoom",staticClass:"canvasGridZoom",attrs:{"id":"canvasGridZoom","width":_vm.width,"height":_vm.height}}),_c('router-link',{attrs:{"to":{
          name: 'checkout',
          params: {
            id: _vm.id,
            quantity: _vm.quantity,
            qx: _vm.xFrame + 1,
            qy: _vm.yFrame + 1,
          },
        }}},[_c('canvas',{ref:"canvasPointer",staticClass:"canvasPointer",attrs:{"id":"canvasPointer","width":_vm.width,"height":_vm.height}})]),_c('canvas',{ref:"canvasZoom",staticClass:"canvasZoom",attrs:{"id":"canvasZoom","width":_vm.width,"height":_vm.height}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }