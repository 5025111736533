<template>
  <div ref="parent" class="canvas-grid">
    <div>
      <div class="canvas-grid__coordinates">
        <v-checkbox
          v-if="enableFrames"
          v-model="showUnavailable"
          label="highlight unavailable frames"
          @change="drawRectangle(false)"
        />
        <p class="font-weight-bold">{{ $t("selectQuadrant") }}</p>
        <div class="mt-1 mb-1">
          {{ `${$t("coordinates")} ${xFrame + 1},${yFrame + 1}` }}
        </div>
        <canvas id="canvasZ" ref="canvasZ" class="canvasZ"></canvas>
        <div class="text-center mt-4">
          <v-btn color="primary" @click="$router.go(-1)">
            <v-icon>mdi-chevron-left</v-icon> {{ $t("back") }}
          </v-btn>
        </div>
      </div>
      <div class="canvas-grid__canvaswrapper">
        {{ debug }}
        <canvas
          id="canvasImg"
          ref="canvasImg"
          class="canvasImg"
          :width="width"
          :height="height"
        ></canvas>
        <canvas
          v-show="showUnavailable"
          id="canvasFrames"
          ref="canvasFrames"
          class="canvasFrames"
          :width="width"
          :height="height"
        ></canvas>
        <canvas
          id="canvasGrid"
          ref="canvasGrid"
          class="canvasGrid"
          :width="width"
          :height="height"
        ></canvas>
        <canvas
          id="canvasGridZoom"
          ref="canvasGridZoom"
          class="canvasGridZoom"
          :width="width"
          :height="height"
        ></canvas>
        <router-link
          :to="{
            name: 'checkout',
            params: {
              id: id,
              quantity: quantity,
              qx: xFrame + 1,
              qy: yFrame + 1,
            },
          }"
        >
          <canvas
            id="canvasPointer"
            ref="canvasPointer"
            class="canvasPointer"
            :width="width"
            :height="height"
          ></canvas>
        </router-link>
        <canvas
          id="canvasZoom"
          ref="canvasZoom"
          class="canvasZoom"
          :width="width"
          :height="height"
        ></canvas>
      </div>
    </div>
  </div>
</template>

<script>
// import PicassoMOSQUETERO from '@/assets/images/Paul-Klee-II.jpg';
import FramesDS from "@/services/FramesDS";
// const info = {
//   _artistID: 'aUlPfs6OybgqrnrnmgmV',
//   _artworkID: 'tm6Fm3TI0DeLwwC246AO',
// };

export default {
  name: "CheckoutQuadrant",
  props: {
    id: String,
    quantity: Number,
  },
  data() {
    return {
      enableFrames: false,
      showUnavailable: true,
      debug: "",
      frameThreshold: 40,
      xFrame: 1,
      yFrame: 1,
      wQuadrants: 1,
      hQuadrants: 1,
      xFramePixels: 0,
      yFramePixels: 0,
      availableFrames: 0,
      availableFrameList: [],
      unavailableFrameList: [],
      cnv: null,
      context: null,
      cnv2: null,
      context2: null,
      cnvZ: null,
      contextZ: null,
      cnvZooming: null,
      contextZooming: null,
      totalFrames: 0,
      hFrames: 0,
      wFrames: 0,
      width: 1024,
      height: 768,
      ratio: 1,
      imgRatio: 1,
      pixelsX: 0,
      pixelsY: 0,
      nX: 0,
      nY: 0,
      pT: 0,
      pB: 0,
      pL: 0,
      pR: 0,
      lastX: null,
      lastY: null,
      img: "",
      scale: 1.0,
      originalWindowWidth: 0,
      originalCanvasWidth: 0,
      translateActivate: false,
      draggin: false,
      clientX: 1,
      clientY: 1,
      resize: 1,
    };
  },

  methods: {
    proceedCheckout() {
      this.$router.push({
        name: "checkout",
        params: {
          id: this.id,
          quantity: this.frames,
          quadrant: [this.xFrame, this.yFrame],
          img: this.img.src,
        },
      });
    },
    startDragging(e) {
      this.dragging = true;
      this.drawRectangle(e);
      // this.debug = 'touch start';
      document.querySelector("html").style.overflowY = "hidden";
    },
    stopDragging() {
      this.dragging = false;
      // this.debug = 'touch end';
      document.querySelector("html").style.overflowY = "";
    },
    drawGrid() {
      this.originalWindowWidth = window.innerWidth;
      this.originalCanvasWidth = this.cnvImg.width;
      this.originalCanvasWidth = this.cnvZooming.width;
      this.cnvPointer.addEventListener("mousemove", this.drawRectangle);
      this.cnvPointer.addEventListener("touchstart", this.startDragging);
      this.cnvPointer.addEventListener("touchmove", this.drawRectangle);
      this.cnvPointer.addEventListener("touchend", this.stopDragging);

      for (let quadrant in this.quadrants) {
        const startFrameX = this.quadrants[quadrant].start[0] - 1;
        const startFrameY = this.quadrants[quadrant].start[1] - 1;
        const endFrameX = this.quadrants[quadrant].end[0];
        const endFrameY = this.quadrants[quadrant].end[1];

        this.drawQuadrant(
          this.pLFrame + startFrameX * this.nXFrame,
          this.pTFrame + startFrameY * this.nYFrame,
          (endFrameX - startFrameX) * this.nXFrame,
          (endFrameY - startFrameY) * this.nYFrame
        );
      }

      // this.nX = Math.floor((this.img.width - this.pL - this.pR) / wSize);
      // this.nY = Math.floor((this.img.height - this.pT - this.pB) / hSize);
      // for (let w = 0; w < wSize; w++) {
      //   for (let h = 0; h < hSize; h++) {
      //     const x = this.pLFrame + w * this.nX;
      //     const y = this.pTFrame + h * this.nY;
      //     this.drawQuadrant(x, y, this.nX, this.nY);
      //   }
      // }
    },
    resizeCanvas() {
      this.scale = window.innerWidth / this.originalWindowWidth;
      this.cnvImg.css("width", this.originalCanvasWidth * this.scale);
      this.cnvZooming.css("width", this.originalCanvasWidth * this.scale);
      this.cnvGrid.css("width", this.originalCanvasWidth * this.scale);
    },
    showAvailableFrames() {
      for (let f = 0; f < this.unavailableFrameList.length; f++) {
        const x =
          this.pLFrame + (this.unavailableFrameList[f].x - 1) * this.nXFrame;
        const y =
          this.pTFrame + (this.unavailableFrameList[f].y - 1) * this.nYFrame;
        this.drawFrame(x, y, this.nXFrame, this.nYFrame);
      }
    },
    drawCellPointer(color, x, y, nX, nY) {
      this.contextPointer.save();
      this.contextPointer.clearRect(
        0,
        0,
        this.cnvPointer.width,
        this.cnvPointer.height
      );
      this.contextPointer.scale(this.scale, this.scale);

      this.contextPointer.lineWidth = 10;
      this.contextPointer.globalAlpha = 0.9;
      this.contextPointer.strokeStyle = color;
      // this.contextPointer.translate(x, y);
      this.contextPointer.strokeRect(x, y, nX, nY);
      this.contextPointer.restore();
    },
    drawFrame(x, y, nX, nY) {
      this.contextFrames.save();
      this.contextFrames.scale(this.scale, this.scale);
      this.contextFrames.globalAlpha = 0.9;
      this.contextFrames.fillStyle = "#f2c602";

      this.contextFrames.fillRect(x, y, nX, nY);
      this.contextFrames.restore();
    },
    drawQuadrant(x, y, nX, nY) {
      this.contextGrid.save();
      this.contextGrid.scale(this.scale, this.scale);
      this.contextGrid.lineWidth = 5;

      this.contextGrid.fillStyle = "#00d100";

      this.contextGrid.strokeRect(x, y, nX, nY);
      this.contextGrid.restore();
    },
    drawRectangle(e) {
      let rect;

      rect = this.cnvImg.getBoundingClientRect();
      if (e) {
        if (e.changedTouches) {
          if (this.translateActivate) {
            e.preventDefault();
          }
          this.clientX = e.changedTouches[0].clientX;
          this.clientY = e.changedTouches[0].clientY;
        } else {
          this.clientX = e.clientX;
          this.clientY = e.clientY;
        }
      }
      const width = rect.right - rect.left;
      const height = rect.bottom - rect.top;
      const xCoord = this.clientX - rect.left - this.pLFrame * this.scale;
      const yCoord = this.clientY - rect.top - this.pTFrame * this.scale;

      this.xFrame = Math.min(
        Math.max(
          0,
          Math.floor(
            xCoord /
              ((width - (this.pLFrame + this.pRFrame) * this.scale) /
                this.wQuadrants)
          )
        ),
        this.wQuadrants - 1
      );
      this.yFrame = Math.min(
        Math.max(
          0,
          Math.floor(
            yCoord /
              ((height - (this.pTFrame + this.pBFrame) * this.scale) /
                this.hQuadrants)
          )
        ),
        this.hQuadrants - 1
      );

      const quadrant = (this.xFrame + 1) * 10 + this.yFrame + 1;
      const startFrameX = this.quadrants[quadrant].start[0] - 1;
      const startFrameY = this.quadrants[quadrant].start[1] - 1;
      const endFrameX = this.quadrants[quadrant].end[0];
      const endFrameY = this.quadrants[quadrant].end[1];

      this.drawCellPointer(
        "aqua",
        this.pLFrame + startFrameX * this.nXFrame,
        this.pTFrame + startFrameY * this.nYFrame,
        (endFrameX - startFrameX) * this.nXFrame,
        (endFrameY - startFrameY) * this.nYFrame
      );

      this.contextZ.fillStyle = "white";
      this.contextZ.fillRect(0, 0, this.cnvZ.width, this.cnvZ.height);

      const imgCanvasScale = height / this.cnvImg.height;
      const xZoomCoord = xCoord / imgCanvasScale;
      const yZoomCoord = yCoord / imgCanvasScale;

      // Workaround for Safari bug failing to draw beyond existing pixels
      const sx = Math.max(xZoomCoord - 60, 0);
      const sy = Math.max(yZoomCoord - 30, 0);
      const sWidth = Math.min(200, width - xCoord + 60);
      const sHeight = Math.min(100, height - yCoord + 30);
      const dx = sWidth * 2;
      const dy = sHeight * 2;

      this.contextZ.drawImage(
        this.cnvZooming,
        sx,
        sy,
        sWidth,
        sHeight,
        0,
        0,
        dx,
        dy
      );
      this.contextZ.drawImage(
        this.cnvGridZoom,
        sx,
        sy,
        sWidth,
        sHeight,
        0,
        0,
        dx,
        dy
      );

      this.contextZ.drawImage(
        this.cnvImg,
        sx,
        sy,
        sWidth,
        sHeight,
        0,
        0,
        dx,
        dy
      );

      if (this.showUnavailable) {
        this.contextZ.drawImage(
          this.cnvFrames,
          sx,
          sy,
          sWidth,
          sHeight,
          0,
          0,
          dx,
          dy
        );
      }

      this.contextZ.drawImage(
        this.cnvGrid,
        sx,
        sy,
        sWidth,
        sHeight,
        0,
        0,
        dx,
        dy
      );
      this.contextZ.drawImage(
        this.cnvPointer,
        sx,
        sy,
        sWidth,
        sHeight,
        0,
        0,
        dx,
        dy
      );
      this.cnvZ.style.top = e.pageY + 10 + "px";
      this.cnvZ.style.left = e.pageX + 10 + "px";
      this.cnvZ.style.display = "block";
    },

    showQuadrants() {
      this.translateActivate = false;
      // Get the area of the image from the selected quadrant
      this.contextZ.clearRect(0, 0, this.cnvZ.width, this.cnvZ.height);
      this.contextGrid.clearRect(0, 0, this.cnvGrid.width, this.cnvGrid.height);
      this.contextPointer.clearRect(
        0,
        0,
        this.cnvPointer.width,
        this.cnvPointer.height
      );

      this.toggleTranslate();

      this.drawGrid(this.wQuadrants, this.hQuadrants);
    },
    toggleTranslate() {
      this.$refs.canvasImg.classList.toggle("canvas-translate");
      this.$refs.canvasPointer.classList.toggle("canvas-translate");
      this.$refs.canvasZoom.classList.toggle("canvas-translate");
      this.$refs.canvasGridZoom.classList.toggle("canvas-translate");
      this.$refs.canvasGrid.classList.toggle("canvas-translate");
      this.$refs.canvasFrames.classList.toggle("canvas-translate");
    },
  },
  beforeMount() {
    if (!this.quantity || this.quantity<10) {
      this.$router.push(`/${this.$store.state.lang}/artwork`);
      return;
    }
    const data = FramesDS.getCoordinateInfo(this.id);
    data
      .then(
        async function (snapshot) {
          if (snapshot.exists()) {
            const snap = snapshot.val();
            this.img = new Image();
            this.img.src = `${process.env.VUE_APP_IMAGES_URL}artwork/${this.id}/${this.id}_0.jpg`;
            this.ratio = snap.ratio;
            this.imgRatio = snap.imgRatio; //this.img.width / this.img.height;
            this.totalFrames = snap.totalFrames;
            this.hFrames = snap.hFrames;
            this.wFrames = snap.wFrames;
            this.nX = snap.nX;
            this.nY = snap.nY;
            this.pL = snap.pL;
            this.pR = snap.pR;
            this.pB = snap.pB;
            this.pT = snap.pT;
            this.nXFrame = snap.nX;
            this.nYFrame = snap.nY;
            this.pLFrame = snap.pL;
            this.pRFrame = snap.pR;
            this.pBFrame = snap.pB;
            this.pTFrame = snap.pT;
            this.resize = snap.imgHeight;

            const canvasWrapperElement = document
              .querySelector(".canvas-grid__canvaswrapper")
              .getBoundingClientRect().height;

            this.height = Math.max(425, Math.floor(canvasWrapperElement)); // resto cabecera, footer y 32px*2 para padding
            this.width = Math.floor(this.height * this.ratio); //this.img.height ;

            this.img.onload = async function () {
              const quadrantsSnapshot = await FramesDS.getQuadrantInfo(this.id);
              this.quadrants = quadrantsSnapshot.val();
              this.scale = this.height / this.img.height;
              this.resize = this.resize / this.img.height;

              this.nXFrame = this.nXFrame / this.resize;
              this.nYFrame = this.nYFrame / this.resize;
              this.pLFrame = this.pLFrame / this.resize;
              this.pRFrame = this.pRFrame / this.resize;
              this.pBFrame = this.pBFrame / this.resize;
              this.pTFrame = this.pTFrame / this.resize;
              this.xFramePixels = this.width / this.wFrames;
              this.yFramePixels = this.height / this.hFrames;
              this.contextImg.drawImage(
                this.img,
                0,
                0,
                this.width,
                this.height
              );

              const availableQuadrants = Object.keys(this.quadrants).map(
                (q) => {
                  return parseInt(q);
                }
              );
              if (availableQuadrants.length > 1) {
                // Too many frames, add sliding interface
                const maxQuadrant = Math.max(...availableQuadrants);

                this.wQuadrants = parseInt(maxQuadrant / 10);
                this.hQuadrants = maxQuadrant - this.wQuadrants * 10;
                this.drawGrid(this.wQuadrants, this.hQuadrants);
              } else {
                // It is ok to show directly all frames
                this.translateActivate = false;
                this.hQuadrants = 1;
                this.wQuadrants = 1;
                this.fullImage = true;
                this.drawGrid(this.wFrames, this.hFrames);
              }
              if (this.enableFrames) {
                FramesDS.getUnavailableFrames(this.id).then((snapshot2) => {
                  const data = snapshot2.val();

                  for (let frameId in data)
                    this.unavailableFrameList.push(data[frameId]);
                  this.showAvailableFrames();
                });
              }
            }.bind(this);
          } else {
            console.log("No data available");
          }
        }.bind(this)
      )
      .catch((error) => {
        console.error(error);
      });
  },
  mounted() {
    this.cnvImg = this.$refs.canvasImg;
    this.cnvFrames = this.$refs.canvasFrames;

    this.cnvPointer = this.$refs.canvasPointer;
    this.cnvZ = this.$refs.canvasZ;
    this.cnvZooming = this.$refs.canvasZoom;
    this.cnvGrid = this.$refs.canvasGrid;
    this.cnvGridZoom = this.$refs.canvasGridZoom;

    this.contextImg = this.cnvImg.getContext("2d");
    this.contextImg.lineWidth = 1;
    this.contextFrames = this.cnvFrames.getContext("2d");
    this.contextFrames.lineWidth = 1;
    this.contextGrid = this.cnvGrid.getContext("2d");
    this.contextGrid.lineWidth = 1;
    this.contextGridZoom = this.cnvGridZoom.getContext("2d");
    this.contextGridZoom.lineWidth = 1;
    this.contextZooming = this.cnvZooming.getContext("2d");
    this.contextZooming.lineWidth = 1;
    this.contextPointer = this.cnvPointer.getContext("2d");
    this.contextPointer.lineWidth = 1;
    this.contextZ = this.cnvZ.getContext("2d");
    this.contextImg.strokeStyle = "rgb(255,0,0)";

    this.contextImg.width = this.width;
    this.contextImg.height = this.height;

    this.contextFrames.width = this.width;
    this.contextFrames.height = this.height;

    this.contextImg.width = this.width;
    this.contextImg.height = this.height;
    this.contextZooming.width = this.width;
    this.contextZooming.height = this.height;

    this.contextGrid.width = this.width;
    this.contextGrid.height = this.height;
    this.contextGridZoom.width = this.width;
    this.contextGridZoom.height = this.height;
  },
  updated() {
    // this.drawGrid();
  },
};
</script>

<style lang="scss">
.canvas-grid {
  display: block;
  justify-content: center;
  min-height: 100%;
  padding-top: 0px;
  @media only screen and (min-width: 768px) {
    padding-top: 0;
    display: flex;
    justify-content: center;
  }
  > div {
    padding: 16px;
    display: flex;
    justify-content: center;
    flex-direction: column;

    @media only screen and (min-width: 768px) and (max-width: 1199px) {
      width: 100%;
      flex-direction: row;
    }
    @media only screen and (min-width: 1200px) {
      flex-direction: row;
      width: 75%;
    }
  }
  &__canvaswrapper {
    overflow: hidden;
    height: 100%;
    position: relative;
    flex-grow: 1;
    flex-shrink: 0;
    border-radius: 4px;
    box-shadow: var(--shadow1);
    border: 1px solid var(--color-grey-dark);
    background-color: var(--color-white);
    order: -1;
    min-height: 440px;
    @media only screen and (min-width: 768px) {
      order: 0;
    }
  }
  &__coordinates {
    align-self: flex-start;
    flex-shrink: 0;
    border-radius: 4px;
    box-shadow: var(--shadow1);
    border: 1px solid var(--color-grey-dark);
    background-color: var(--color-white);
    margin: 16px auto;
    padding: 8px;
    @media only screen and (min-width: 768px) and (max-width: 1199px) {
      flex-basis: 30vw;
      margin-right: 16px;
      margin-top: 0;
    }
    @media only screen and (min-width: 1200px) {
      flex-basis: 400px;
      margin-top: 0;
      margin-right: 16px;
      padding: 16px;
    }
  }
}
.canvasImg,
.canvasPointer,
.canvasGrid,
.canvasGridZoom,
.canvasFrames,
.canvasZoom {
  position: absolute;
  left: 50%;
  top: 50%;
  max-width: 95%;
  transform: translate(-50%, -50%);
  transition: transform 0.4s linear;
}
.canvasGrid,
.canvasFrames,
.canvasImg {
  &.canvas-translate {
    transform: translate(-280%, -50%);
  }
}

.canvasImg {
  z-index: 1;
}
.canvasPointer {
  z-index: 99;
}
.canvasZ {
  width: 100%;
  z-index: 3;
  box-shadow: var(--shadow1);
}
.canvasGridZoom,
.canvasGrid {
  z-index: 5;
}

.canvasGridZoom,
.canvasZoom {
  transform: translate(280%, -50%);
  &.canvas-translate {
    transform: translate(-50%, -50%);
  }
}

.back-to-complete {
  position: fixed;
  top: 64px;
  left: 0px;
  z-index: 4;
  @media only screen and (min-width: 768px) {
    position: static;
  }
  &__text {
    display: none;
    @media only screen and (min-width: 768px) {
      display: inline-block;
    }
  }
}
</style>
